import Cards from "../../components/cards"
import Cta from "../../components/cta"
import Footer from "../../components/footer"
import Header from "../../components/header"
import Hero from "../../components/webdev/hero"
// import Languages from "../../components/webdev/languages"
import Layout from "../../components/layout"
import React from "react"
import Seo from "../../components/seo2"
import { getSEOByIdentifier } from "../../services/seo.service";
import ServiceLinks from '../../components/services/serviceLinks'
import { getStaticContentByIdentifier } from "../../services/misc.service";

const service = 0;

const WebDevPage = props => {
  const staticContent = props.pageContext.staticContent;
  const staticImages = props.pageContext.staticImages;
  const serviceArray = props.pageContext.services;
  const cards = props.pageContext.cards;
  const SEOInfo = props.pageContext.SEOInfo;

  return (
    <Layout>
      <Seo SEOInfo={getSEOByIdentifier('web-development', SEOInfo)}/>
      <Header />
      <Hero service={serviceArray[service]} staticContent={staticContent} staticImages={staticImages}/>
      <Cards cards={cards} />
      <ServiceLinks  pos={service} serviceArray={serviceArray}/>
      {/* <Languages /> */}
      <Cta title={getStaticContentByIdentifier('WAD.CTA.content', staticContent)} button={getStaticContentByIdentifier('WAD.CTA.button', staticContent)} />
      <Footer />
    </Layout>
  )
}

export default WebDevPage;
