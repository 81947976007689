import "./hero.scss"

import React from "react"
import backArrow from '../../../static/images/icon-back-arrow.svg'
import heroImage from '../../../static/images/webdev-detail/webdev-hero.svg'
import { getStaticContentByIdentifier } from "../../services/misc.service";
import { getStaticImageByIdentifier } from "../../services/image.service";

export default (props) => {
    const staticContent = props.staticContent;
    const staticImages = props.staticImages;
    const service = props.service;

    return (
        <section className="text">
            <div className="link-bar">
                <a href="/services" className="return-link row"><img className="back-arrow" alt="return arrow for link" src={backArrow}/>BACK TO SERVICES</a>
            </div>
            <div className="bg-gradient">
                <div className="row middle-md my-auto">
                    <div className="rows-center mobile-reverse">
                        <div className="col-xs-12 col-md-6 align-items-center o1">
                            <img 
                                id = "illustration"
                                src={getStaticImageByIdentifier('WAD.hero.image', staticImages)}
                                alt="Illustration of a computer running web applications"
                                className="hero-illustration"
                            />
                        </div>
                        <div className="col-xs-12 col-md-6 align-items-center o2">
                        <h1 className = "text-center service-title">{service.title}</h1>
                            <p className = "text-center">{service.body}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="bg-light">
                <div className="row middle-md padding-t-3">
                    <div className="col-xs-12 col-md-12 align-items-center">
                        <h2 className = "text-center">{getStaticContentByIdentifier('WAD.subtitle1', staticContent)}</h2>
                        <p className = "text-center">{getStaticContentByIdentifier('WAD.content1', staticContent)} </p>
                    </div>
                </div>
            </div>
        </section>
    )
}
